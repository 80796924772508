import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import Layout from "components/layout"
import JobBlock from "components/JobBlock"
import ResourcePageHeader from "components/ResourcePageHeader"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"

const jobsPage = ({ path, pageContext, data, rules, styles }) => {
  const {
    title,
    meta,
    slug,
    intro,
  } = data.sanityJobsPage
  const jobs = data.allSanityJob.edges
  return (
    <Layout
      pageColor={colors.colorFlow300}
      linkColor={colors.colorFlow400}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      <ResourcePageHeader title={title} intro={intro} breadcrumbLeft={"JOBS"} pageColor={colors.colorFlow300} />

      <div>
        {jobs && jobs.map(({ node: job }, index) =>
          <JobBlock
            key={'job' + index}
            version={"section"}
            odd={(index + 1) % 2}
            extend={{styles: rules.job}}
            {...job} />
        )}
      </div>
    </Layout>
  )
}

const styles = () => ({
  job: {
    zIndex: 1,
    position: 'relative',
    ...fluidValues({marginBottom: [60, 200]}),
    ':first-of-type': {
      onBefore: {
        position: 'absolute',
        top: '-10%',
        left: '18%',
        width: 1,
        display: 'block',
        backgroundColor: '#000000',
        content: '""',
        ...fluidValues({height: [109, 256]}),
        '@media(min-width: 1000px)': {
          top: '-24%',
        }
      }
    }
  },
})

export default connectFela(styles)(jobsPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    allSanityJob (
      sort: {fields: _createdAt, order: DESC},
      filter: { countrySet: { eq: $country }}
    )  {
      edges {
        node {
          countrySet
          _createdAt
          title
          extraAuthor
          published
          intro
          slug {
            current
          }
          listingImage {
            ...ImageWithPreview
          }
          author {
            name
          }
        }
      }
    }
    sanityJobsPage(countrySet: { eq: $country }) {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      intro
    }
  }
`
