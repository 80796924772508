import React, { useState } from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"

import Button from "components/Button"
import Container from "components/container"
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import Link from "components/Link"
import P from "components/typography/P"
import calc from "utilities/calc"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import ArticleCard from "components/ArticleCard"
import { firstSentenceAndRest } from "utilities/tools"

const JobBlock = ({
  author,
  extraAuthor,
  listingImage,
  intro,
  published,
  rules,
  slug,
  styles,
  title,
}) => {
  let [firstSentence, theRest] = firstSentenceAndRest(intro)

  return (<Container flex withContentArea5 extend={{styles: rules.styles}}>
    {listingImage &&listingImage.asset && <SanityImage {...listingImage} className={styles.image} width={654} height={654} alt={title} config={{fit: 'crop'}} />}

    <div className={styles.contentContainer}>
      <ArticleCard
        extend={{styles: rules.article, wrap: rules.articleWrap}}
        title={title}
        author={author}
        extraAuthor={extraAuthor}
        published={published}
        linkBgColor={colors.colorFlow400}
        limitFlag={true}
        cardIntro={firstSentence}
        excerpt={theRest}

        articleType={"JOBS"}
        slug={{ current: '/jobs/' + slug.current }} />
    </div>
  </Container>)
}

/*
 */
const styles = (props) => ({
  articleWrap: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  article: {
    maxWidth: 'unset',
    width: '100%'
  },
  image: {
    flex: '1 1 100%',
    marginBottom: 32,
    objectFit: 'cover',
    order: 0,
    width: '100%',
    maxWidth: 654,
    maxHeight: 654,

    '@media(min-width: 1000px)': {
      flex: '1 1 50%',
      width: '50%',
      marginBottom: 'auto',
      marginTop: 'auto',
      order: (props.odd ? 0 : 1 ),
    },
  },
  contentContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    width: '100%',

    '@media(min-width: 1000px)': {
      flex: '1 1 47%',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },

    extend: [
      {
        condition: props.odd === 1,
        style: {
          '@media(min-width: 1000px)': {
            paddingLeft: calc(24, 110, 660, 1440),
          }
        }
      },
      {
        condition: props.odd === 0,
        style: {
          '@media(min-width: 1000px)': {
            paddingRight: calc(24, 110, 660, 1440),
          }
        }
      }
    ]
  },
  title: {
    marginTop: 0,
    marginBottom: 12,
  },
  subtitle: {
    marginBottom: 24,
  },
  excerpt: {
    ...fluidValues({marginBottom: [19, 14]}),
  },
})

export default connectFela(styles)(JobBlock)
